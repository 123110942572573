import React, { useEffect, useState } from "react";
import axios from "axios";
import "./header.css";
import { Carousel } from "antd";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import movieTicket from "../../assest/ticket.jpg";
import eventTicket from "../../assest/ticket new.png";

const contentStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Header = () => {
  const [movie, setMovie] = useState(null);
  const [event, setEvent] = useState(null);

  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchLatestData = async () => {
      try {
        const movieResponse = await axios.get(
          "https://showzsbackend.smartvaathi.com/api/movie"
        );
        const eventResponse = await axios.get(
          "https://showzsbackend.smartvaathi.com/api/live-events/showLastEvent"
        );
        setMovie(movieResponse.data);
        setEvent(eventResponse.data);
      } catch (error) {
        console.error("Failed to fetch latest data:", error);
      }
    };

    fetchLatestData();
  }, []);

  const handleMovieTicketClick = () => {
    navigate("/Movie");
  };

  const handleEventTicketClick = () => {
    navigate("/Event");
  };

  return (
    <section className="header">
      <div className="header-container">
        <div className="overlay"></div>
        <div className="carousel-overlay">
          {movie && event && (
            <Carousel autoplay>
              <div>
                <div style={contentStyle}>
                  <img
                    src={movie.carousel_images}
                    alt={movie.title}
                    className="carousel-img"
                  />
                  <div
                    className="ticket-overlay"
                    onClick={handleMovieTicketClick}
                  >
                    <img
                      src={movieTicket}
                      alt="Movie Ticket"
                      className="ticket-img"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div style={contentStyle}>
                  <img
                    src={event.carousel_images}
                    alt={event.title}
                    className="carousel-img"
                  />
                  <div
                    className="ticket-overlay"
                    onClick={handleEventTicketClick}
                  >
                    <img
                      src={eventTicket}
                      alt="Event Ticket"
                      className="ticket-img"
                    />
                  </div>
                </div>
              </div>
            </Carousel>
          )}
        </div>
      </div>
    </section>
  );
};

export default Header;
