import React, { useEffect, useState } from "react";
import "./moviewatch.css";
import ReactPlayer from "react-player";
import axios from "axios";
import { notification } from "antd"; // For error or success notifications
import { ClipLoader } from "react-spinners"; // Loading spinner

const MovieWatch = () => {
  const [movies, setMovies] = useState([]);
  const [currentMovieLink, setCurrentMovieLink] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [selectedMovie, setSelectedMovie] = useState(null); // For better UI feedback

  useEffect(() => {
    setLoading(true); // Start loading

    // Disable save shortcuts
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "s") {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Retrieve user data from localStorage
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);

      // Fetch movies the user has successfully paid for
      axios
        .post("https://showzsbackend.smartvaathi.com/api/check-movie-payment", {
          email: user.email,
        })
        .then((response) => {
          setLoading(false); // Stop loading
          if (response.data.status === "success") {
            setMovies(response.data.movies);
            if (response.data.movies.length > 0) {
              setCurrentMovieLink(response.data.movies[0].link); // Set first movie link as default
              setSelectedMovie(response.data.movies[0].title);
              fetchPlaybackProgress(response.data.movies[0].link);
            }
          } else {
            notification.error({
              message: "Error",
              description:
                "No paid movies found. Please complete a payment first.",
            });
          }
        })
        .catch((error) => {
          setLoading(false); // Stop loading on error
          console.error(
            "There was an error checking the movie payment status!",
            error
          );
        });
    } else {
      setLoading(false); // Stop loading
      console.error("No user data found in session storage.");
      notification.error({
        message: "Login Required",
        description: "Please log in to access your movies.",
      });
    }

    // Cleanup
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleMovieSelect = (e) => {
    const selectedLink = e.target.value;
    const selectedTitle = e.target.options[e.target.selectedIndex].text;
    setCurrentMovieLink(selectedLink);
    setSelectedMovie(selectedTitle);
    fetchPlaybackProgress(selectedLink); // Fetch progress for the selected movie
  };

  const savePlaybackProgress = (movieLink, playbackPosition) => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);

      axios
        .post(
          "https://showzsbackend.smartvaathi.com/api/save-playback-progress",
          {
            email: user.email,
            movie_link: movieLink,
            playback_position: playbackPosition,
          }
        )
        .then((response) => {
          console.log("Progress saved successfully:", response.data);
        })
        .catch((error) => {
          console.error(
            "Failed to save playback progress:",
            error.response?.data || error.message
          );
          // notification.error({
          //   message: 'Error',
          //   description: 'Failed to save playback progress. Please try again.',
          // });
        });
    }
  };

  const fetchPlaybackProgress = (movieLink) => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);

      axios
        .post(
          "https://showzsbackend.smartvaathi.com/api/get-playback-progress",
          {
            email: user.email,
            movie_link: movieLink,
          }
        )
        .then((response) => {
          if (
            response.data.status === "success" &&
            response.data.playback_position
          ) {
            setTimeout(() => {
              document.querySelector(".react-player video").currentTime =
                response.data.playback_position;
            }, 1000); // Allow time for video player to load
          }
        })
        .catch((error) => {
          console.error("Error fetching playback progress:", error);
        });
    }
  };

  const handleProgress = (progress) => {
    // Save progress only if playedSeconds changes
    savePlaybackProgress(currentMovieLink, progress.playedSeconds);
  };

  return (
    <section className="movie-watch">
      <h2>Watch Movie</h2>
      {loading ? (
        <div className="loading-spinner">
          <ClipLoader color="#36d7b7" loading={loading} size={50} />
        </div>
      ) : movies.length > 0 ? (
        <>
          <div className="movie-select-container">
            <select onChange={handleMovieSelect} className="movie-select">
              {movies.map((movie, index) => (
                <option key={index} value={movie.link}>
                  {movie.title}
                </option>
              ))}
            </select>
          </div>
          <div
            className="movie-watch-container"
            style={{ position: "relative" }}
          >
            {currentMovieLink && (
              <>
                <div
                  className="player-wrapper"
                  onContextMenu={(e) => e.preventDefault()} // Disable right-click
                >
                  <ReactPlayer
                    url={currentMovieLink}
                    controls
                    className="react-player"
                    config={{
                      file: {
                        attributes: {
                          controlsList: "nodownload", // Disable download button
                          disablePictureInPicture: true, // Disable PiP
                        },
                      },
                    }}
                    onProgress={handleProgress} // Track playback progress
                  />
                </div>
                <div
                  className="player-overlay"
                  onContextMenu={(e) => e.preventDefault()}
                ></div>
              </>
            )}
            {selectedMovie && <p>Now Watching: {selectedMovie}</p>}
            <div className="expiration-notice">
              <strong>Notice:</strong> Enjoy your movie! 🎬 Your access will be
              available for the next 12 days. After that, it will expire, so
              make sure to watch it before time runs out! ⏳
            </div>
          </div>
        </>
      ) : (
        <p>
          No movies available to watch. Please make sure you have completed the
          payment.
        </p>
      )}
    </section>
  );
};

export default MovieWatch;
