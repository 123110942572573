import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import ticket from "../../assest/ticket.jpg";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { ClipLoader } from "react-spinners"; // Importing the spinner component
import "./movieDetails.css";

const MovieDetails = () => {
  const [movie, setMovie] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const playerRef = useRef(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "s") {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    const selectedMovie = sessionStorage.getItem("selectedMovie");
    if (selectedMovie) {
      setMovie(JSON.parse(selectedMovie));
      setLoading(false); // Stop loading after getting data from sessionStorage
    } else {
      axios
        .get("https://showzsbackend.smartvaathi.com/api/movie")
        .then((response) => {
          console.log("Movie data:", response.data);
          setMovie(response.data);
          setLoading(false); // Stop loading once data is fetched
        })
        .catch((error) => {
          console.error("Failed to fetch movie data:", error);
          setLoading(false); // Stop loading in case of error as well
        });
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleWatchTrailer = () => {
    if (movie && movie.trailer) {
      setShowModal(true);
      setTimeout(() => enterFullScreen(), 100); // Enter fullscreen after modal opens
    } else {
      console.error("No valid trailer URL found:", movie?.trailer);
    }
  };

  const handleCloseModal = () => {
    exitFullScreen(); // Exit fullscreen if modal is closed
    setShowModal(false);
  };

  const enterFullScreen = () => {
    if (playerRef.current && playerRef.current.wrapper) {
      const videoElement = playerRef.current.wrapper;
      setIsFullscreen(true); // Set fullscreen state to true
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        videoElement.mozRequestFullScreen();
      } else if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen();
      }
    }
  };

  const exitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    setIsFullscreen(false); // Reset fullscreen state
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullscreen(false); // Exit fullscreen if user presses ESC or exits fullscreen
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () =>
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
  }, []);

  const handleBuyNow = () => {
    if (movie) {
      localStorage.setItem("selectedItem", JSON.stringify(movie));
      navigate("/checkout", { state: { movie } });
    }
  };

  return (
    <section className="movie-details">
      {loading ? (
        <div className="loading-spinner">
          <ClipLoader color="#36d7b7" loading={loading} size={50} />
        </div>
      ) : movie ? (
        <>
          <div className="movie-info">
            <h1>{movie.title}</h1>
            <div className="movie-rating">
              <p>Directed by: {movie.director}</p>
              <p>Genre: {movie.genre}</p>
              <p>Duration: {movie.duration} Min</p>
            </div>
            <div className="movie-actions">
              <button className="watch-trailer" onClick={handleWatchTrailer}>
                Watch Trailer
              </button>
              <div className="buy-now" onClick={handleBuyNow}>
                <img src={ticket} alt="Ticket" className="ticketImg" />
              </div>
            </div>
          </div>
          <div className="movie-poster">
            <div className="poster-container">
              {movie.picture && <img src={movie.picture} alt={movie.title} />}
              <div className="movie-details-overlay">
                <h2>{`${movie.title} - The Movie`}</h2>
                <p>Directed by {movie.director}</p>
                <p>Genre: {movie.genre}</p>
                <p>Duration: {movie.duration} Min</p>
              </div>
            </div>
          </div>

          {showModal && movie.trailer && (
            <div className="modal-overlay">
              <div
                className="modal-content"
                onContextMenu={(e) => e.preventDefault()} // Disable right-click
              >
                <span className="close-button" onClick={handleCloseModal}>
                  &times;
                </span>
                <ReactPlayer
                  ref={playerRef}
                  url={movie.trailer}
                  playing
                  controls
                  className={`trailer-video ${
                    isFullscreen ? "fullscreen" : ""
                  }`}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload", // Disable download option
                        disablePictureInPicture: true, // Disable PiP
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <p>No movie details available at the moment.</p>
      )}
    </section>
  );
};

export default MovieDetails;
