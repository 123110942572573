import React, { useState, useEffect } from "react";
import "./AdminFooterUpdate.css";

const FooterAdmin = () => {
  const [footerHeading, setFooterHeading] = useState("");
  const [newHeading, setNewHeading] = useState("");

  useEffect(() => {
    fetch("https://showzsbackend.smartvaathi.com/api/footer-heading")
      .then((response) => response.json())
      .then((data) => setFooterHeading(data.footer_heading));
  }, []);

  const handleUpdate = () => {
    fetch("https://showzsbackend.smartvaathi.com/api/footer-heading", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ footer_heading: newHeading }),
    })
      .then((response) => response.json())
      .then((data) => {
        alert(data.message);
        setFooterHeading(newHeading);
      });
  };

  return (
    <div className="footer-admin-container">
      <h1>Footer Admin Panel</h1>
      <p className="footer-admin-current-heading">
        <b>Current Footer Heading:</b> {footerHeading || "Loading..."}
      </p>
      <div className="footer-admin-input-container">
        <input
          type="text"
          className="footer-admin-input"
          value={newHeading}
          onChange={(e) => setNewHeading(e.target.value)}
          placeholder="Enter new footer heading"
        />
        <button className="footer-admin-button" onClick={handleUpdate}>
          Update Footer Heading
        </button>
      </div>
    </div>
  );
};

export default FooterAdmin;
