import React, { useState, useEffect } from "react";
import { Table, Button, Popconfirm, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";

const EditorTable = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch all editors and content owners
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://showzsbackend.smartvaathi.com/api/get-editors"
      );
      setUsers(response.data); // Ensure backend returns a proper JSON response
      setLoading(false);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to fetch users.",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Handle user deletion
  const deleteUser = async (id) => {
    try {
      await axios.delete(
        `https://showzsbackend.smartvaathi.com/api/delete-editor/${id}`
      );
      notification.success({
        message: "Success",
        description: "User deleted successfully.",
      });
      setUsers(users.filter((user) => user.id !== id)); // Update UI after deletion
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to delete user.",
      });
    }
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Category",
      dataIndex: "user_type", // Assuming the field in your backend response is user_type
      key: "user_type",
      render: (user_type) => {
        if (user_type === 3) return "Editor";
        if (user_type === 4) return "Content Owner";
        return "Unknown"; // Fallback for unexpected values
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure you want to delete this user?"
          onConfirm={() => deleteUser(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <h2>
        <center>Manage Editors and Content Owners</center>
      </h2>
      <Table
        dataSource={users}
        columns={columns}
        rowKey="id"
        loading={loading}
        bordered
      />
    </div>
  );
};

export default EditorTable;
