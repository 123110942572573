import React from 'react'
import { Footer, ThankyouPayment } from '../../containers';
import { Navbar } from '../../components';

const PaymentSuccessful = () => {
    return (
        <div>
            <Navbar />
            <ThankyouPayment />
            <Footer />
        </div>
    )
}

export default PaymentSuccessful