import React, { useState, useEffect } from "react";
import "./footer.css";
import logo from "../../assest/web-shosz-1.png";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
// import AppStore from '../../assest/app.png';
// import GooglePlay from '../../assest/android.png';

const Footer = () => {
  const [footerHeading, setFooterHeading] = useState(""); // State to store the footer heading

  // Fetch the footer heading from the backend API
  useEffect(() => {
    fetch("https://showzsbackend.smartvaathi.com/api/footer-heading") // Replace with your API URL
      .then((response) => response.json())
      .then((data) => {
        if (data.footer_heading) {
          setFooterHeading(data.footer_heading);
        }
      })
      .catch((error) => {
        console.error("Error fetching footer heading:", error);
      });
  }, []);

  return (
    <div className="footer section_padding">
      <div className="footer-heading">
        <h1 className="gradient_text">{footerHeading || "Loading..."}</h1>
      </div>

      <div className="footer-links">
        <div className="footer-link_logo">
          <img src={logo} alt="logo" />
        </div>

        <div className="footer-link_div">
          <h4>Company</h4>
          <a href="https://globalmeshsolutions.com">
            <p>Global Mesh Solutions (Pvt) Ltd</p>
          </a>
        </div>

        <div className="footer-link_div">
          <h4>Links</h4>
          <p>
            <Link to="/TermsAndConditions">Terms & Conditions</Link>
          </p>
          <p>
            <Link to="/PrivacyPolicy">Privacy Policy</Link>
          </p>
          <p>
            <Link to="/RefundPolicy">Refund Policy</Link>
          </p>
          <p>
            <Link to="/Contactus">Contact</Link>
          </p>
        </div>

        <div className="footer-link_div">
          <h4>Get in touch</h4>
          <p>Info@Globalmeshsolutions.com</p>
          <p>(+94)76-5299400</p>
          <p>
            Global Mesh Solutions (Pvt) Ltd.
            <br /> No- 7B, Floor 25 1/2, Ocean Tower
            <br /> Building, Station Road,
            <br /> Colombo 04,
            <br /> Srilanka 00400.
          </p>
        </div>
      </div>

      <div className="footer-divider"></div>
      <div className="footer-copyright">
        <div className="footer-social-icons">
          <a
            href="https://www.facebook.com/profile.php?id=61566882961308"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.tiktok.com/@showzs8?is_from_webapp=1&sender_device=pc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTiktok />
          </a>
          <a
            href="https://www.instagram.com/show.zsanytime/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </div>
        <div className="footer-app-download">
          <a
            href="https://drive.google.com/file/d/152ZCFn2H4nHncBsPswcBeRLcJ6PcDpsO/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Download Our App</p>
            {/* <img src={GooglePlay} alt="Google Play" /> */}
          </a>
        </div>
        <p className="footer-copyright-text">
          Copyright © 2024 Global Mesh. All rights reserved.
          <br />
          Powered by eSupport Technologies Pvt. Ltd
        </p>
      </div>
    </div>
  );
};

export default Footer;
