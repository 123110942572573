import React, { useEffect, useState } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners"; // Import the spinner
import "./UpCommingMovies.css";

const UpCommingMovies = () => {
  const [upcomingMovies, setUpcomingMovies] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    fetchUpcomingMovies();
  }, []);

  const fetchUpcomingMovies = async () => {
    try {
      const response = await axios.get(
        "https://showzsbackend.smartvaathi.com/api/upcoming-movies/latest"
      );
      setUpcomingMovies(response.data.upcomingmovie);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error fetching upcoming movies:", error);
      setLoading(false); // Stop loading even on error
    }
  };

  return (
    <section className="upcoming-movies">
      <h2>What Next!</h2>

      {loading ? (
        <div className="loading-spinner">
          <ClipLoader size={50} color="#36D7B7" loading={loading} />
        </div>
      ) : (
        <div
          className={`upcoming-movies-container ${
            Array.isArray(upcomingMovies) && upcomingMovies.length === 1
              ? "single-movie"
              : ""
          }`}
        >
          {Array.isArray(upcomingMovies) && upcomingMovies.length > 0 ? (
            upcomingMovies.slice(0, 4).map((movie) => (
              <div
                key={movie.id}
                className="movie-card animate__animated animate__fadeInUp"
              >
                <img src={movie.image} alt={movie.title} />
                <div className="movie-info">
                  <h3>{movie.title}</h3>
                  <p>{movie.description}</p>
                  <p>Release Date: {movie.date}</p>
                  <p>Duration: {movie.duration}</p>
                  <p>Category: {movie.category}</p>
                  <p>Ticket Price: {movie.price}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No upcoming movies available at the moment.</p>
          )}
        </div>
      )}
    </section>
  );
};

export default UpCommingMovies;
