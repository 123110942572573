import React, { useState, useEffect } from "react";
import background from "../../assest/banner.jpg";
import "./login.css";
import { FcGoogle } from "react-icons/fc";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { notification } from "antd"; // Import Ant Design notification
import "antd/dist/reset.css"; // Ensure Ant Design CSS is imported/reset
import { useGoogleLogin } from "@react-oauth/google";
import ForgotPassword from "../../containers/ForgotPassword/ForgotPassword"; // Import the ForgotPassword component
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isForgotPasswordVisible, setForgotPasswordVisible] = useState(false); // State to control modal visibility
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get("status");

    if (status === "success") {
      notification.success({
        message: "Email verified successfully!",
        placement: "topRight",
      });
    } else if (status === "failed") {
      notification.error({
        message: "Email verification failed.",
        placement: "topRight",
      });
    }
  }, [location.search]);

  // Open notification helper function
  const openNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  // Validate form fields
  const validate = () => {
    const newErrors = {};

    if (!email) {
      newErrors.email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (!password) {
      newErrors.password = "Password is required";
    }

    return newErrors;
  };

  const handleSignin = async (e) => {
    e.preventDefault();
    setErrors({});
    setLoading(true);

    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      openNotification(
        "error",
        "Login Error",
        "Please correct the errors before submitting."
      );
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "https://showzsbackend.smartvaathi.com/api/login",
        { email, password }
      );

      const user = response.data.user;
      const role = response.data.role;

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("userRole", role);

      if (role === "admin") {
        openNotification(
          "success",
          "Login Successful",
          "Admin logged in successfully."
        );
        navigate("/admin");
      } else if (role === "editor") {
        openNotification(
          "success",
          "Login Successful",
          "Editor logged in successfully."
        );
        navigate("/admin");
      } else if (role === "content_owner") {
        openNotification(
          "success",
          "Login Successful",
          "Content Owner logged in successfully."
        );
        navigate("/admin");
      } else {
        openNotification("success", "Login Successful", "Welcome back.");
        navigate("/");
      }
    } catch (error) {
      let errorMessage = "An unexpected error occurred.";

      if (error.response) {
        if (error.response.data.errors) {
          setErrors(error.response.data.errors);
        } else if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }
      } else {
        errorMessage = "Network error. Please try again.";
      }
      openNotification("error", "Login Failed", errorMessage);
      setErrors({ general: errorMessage });
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = () => {
    navigate("/Register");
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const response = await axios.post(
          "https://showzsbackend.smartvaathi.com/api/google-login",
          {
            token: tokenResponse.access_token,
          }
        );

        const user = response.data.user;
        const role = response.data.user_type;

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("userRole", role);

        openNotification(
          "success",
          "Google Sign-In",
          "Google Sign-In successful!"
        );

        if (role === "admin" || role === "content_owner" || role === "editor") {
          navigate("/admin");
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error(error);
        openNotification(
          "error",
          "Google Sign-In Failed",
          "Google Sign-In failed!"
        );
      }
    },
    onError: () => {
      openNotification(
        "error",
        "Google Sign-In Failed",
        "Google Sign-In failed!"
      );
    },
  });

  // Functions to open and close the ForgotPassword modal
  const showForgotPasswordModal = () => {
    setForgotPasswordVisible(true);
  };

  const closeForgotPasswordModal = () => {
    setForgotPasswordVisible(false);
  };

  // Handle Resend Verification Email
  const handleResendVerification = async () => {
    if (!email) {
      openNotification(
        "error",
        "Email Required",
        "Please enter your email to resend verification."
      );
      return;
    }

    setResendLoading(true);

    try {
      const response = await axios.post(
        "https://showzsbackend.smartvaathi.com/api/resend-verification-email",
        {
          email,
        }
      );

      openNotification(
        "success",
        "Verification Email Sent",
        response.data.message
      );
    } catch (error) {
      let errorMessage = "An unexpected error occurred.";
      if (error.response && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      openNotification("error", "Resend Failed", errorMessage);
    } finally {
      setResendLoading(false); // Stop spinner
    }
  };

  return (
    <>
      <section className="login">
        <div className="overlay"></div>
        <img src={background} alt="login-background" />
        <div className="login-box">
          <h2 style={{ textAlign: "center" }} className="login-heading">
            Login
          </h2>
          {loading ? (
            <div className="spinner-container">
              <Spin indicator={antIcon} tip="Signing in..." />
            </div>
          ) : (
            <>
              <div className="login-container">
                <button className="google-login" onClick={googleLogin}>
                  <FcGoogle className="icon" /> Sign in with Google
                </button>
              </div>
              <div className="separator">
                <span>or</span>
              </div>
              <form onSubmit={handleSignin}>
                <div className="form-details">
                  <input
                    type="email"
                    placeholder="Email address"
                    className="input-field-login"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                  <input
                    type="password"
                    placeholder="Password"
                    className="input-field-login"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors.password && (
                    <span className="error">{errors.password}</span>
                  )}
                  {errors.general && (
                    <span className="error">{errors.general}</span>
                  )}
                </div>
                <div className="options">
                  <label className="checkbox">
                    <input type="checkbox" /> Remember me
                  </label>
                  <a
                    href="#"
                    onClick={showForgotPasswordModal}
                    className="forgot-password"
                  >
                    Forgot password?
                  </a>
                  <button
                    type="button"
                    onClick={handleResendVerification}
                    className="resend-verification"
                    disabled={resendLoading}
                  >
                    {resendLoading ? (
                      <LoadingOutlined
                        spin
                        style={{
                          fontSize: 16,
                          color: "#1890ff",
                          marginRight: "8px",
                        }}
                      />
                    ) : null}
                    {resendLoading ? "Sending..." : "Resend Verification Email"}
                  </button>
                </div>
                <button type="submit" className="signin-button">
                  SIGN IN
                </button>
              </form>
            </>
          )}
          <div className="register-link">
            Don't have an account?{" "}
            <button className="reg-btn" onClick={handleRegister}>
              Register
            </button>
          </div>
        </div>
      </section>

      {/* ForgotPassword Modal */}
      <ForgotPassword
        visible={isForgotPasswordVisible}
        onClose={closeForgotPasswordModal}
      />
    </>
  );
};

export default Login;
