import React from 'react';
import { Card, Typography, Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import './ThankyouPayment.css';

const { Title, Text } = Typography;

const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } }
};

const ThankyouPayment = () => {
    return (
        <div className="payment-success-container">
            <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                className="payment-success-card-container"
            >
                <Card className="payment-success-card" bordered={false}>
                    <motion.div initial="hidden" animate="visible" variants={fadeIn} className="icon-container">
                        <CheckCircleOutlined className="success-icon" />
                    </motion.div>
                    <motion.div initial="hidden" animate="visible" variants={fadeIn}>
                        <Title level={2} className="success-title">
                            Congratulations! Your Payment Has Been Successful
                        </Title>
                    </motion.div>
                    <motion.div initial="hidden" animate="visible" variants={fadeIn}>
                        <Text className="success-message">
                            Thank you for your payment! Your transaction has been successfully completed.
                        </Text>
                    </motion.div>
                    <motion.div initial="hidden" animate="visible" variants={fadeIn}>
                        <Button type="primary" className="return-button" href="/">
                            Click WatchList To Watch Movies & Events
                        </Button>
                    </motion.div>
                </Card>
            </motion.div>
        </div>
    );
};

export default ThankyouPayment;
